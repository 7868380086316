import React from "react"
import PropTypes from "prop-types"
import * as style from "./content.module.scss"

const Content = ({children, extraClass}) => (
  <div className={style.content + ' ' + extraClass}>
    {children}
  </div>
)


export default Content

Content.propTypes = {
  children: PropTypes.node.isRequired,
  extraClass: PropTypes.string
}
Content.prodefaultPropspTypes = {
  extraClass: ``
}
