// extracted by mini-css-extract-plugin
export var album = "album-module--album--2pIFc";
export var album__cover = "album-module--album__cover--ElOSJ";
export var album__details = "album-module--album__details--2mpsr";
export var album__title = "album-module--album__title--7lnlR";
export var album__description = "album-module--album__description--1-Qi0";
export var synopsis = "album-module--synopsis--e7bBz";
export var album__links = "album-module--album__links--3CKg-";
export var album__button = "album-module--album__button--2zfZS";
export var albumDetails = "album-module--albumDetails--Z8NQv";
export var albumDetails__close = "album-module--albumDetails__close--1sNX3";
export var albumDetails__cover = "album-module--albumDetails__cover--2EBsJ";
export var albumDetails__credits = "album-module--albumDetails__credits--2SLYL";
export var albumDetails__tracks = "album-module--albumDetails__tracks--lu1np";