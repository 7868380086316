import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const CakeEP = ({ data }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
            <div>
              {/* Not sure */}
            </div>
          )}

        <Content extraClass='content--modal'>
          <div className="column column--40">
            <div className="sticky">

              <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

              <p className={style.albumDetails__credits}>
                <strong>Executive Producer:</strong> Specyal T<br/>
                <strong>Label:</strong> Independent<br/>
                <strong>Recorded At:</strong> Avenue Kode Studios<br/>
                <strong>Production Provided By:</strong> Daniel Cruz<br/>
                <strong>Mixed & Mastered By:</strong> Felix Khan
              </p>

              <div className={Contentstyle.subtitleLowercase}>Tracks</div>

              <ol className={style.albumDetails__tracks}>
                <li>Ripped Jeans</li>
                <li>Sunday Siesta</li>
                <li>O Therapy</li>
                <li>Just Desserts</li>
                <li>Lip Service </li>
              </ol>

            </div>

          </div>
          <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

            <p><strong>Ripped Jeans</strong><br/>
            On the way to band rehearsal, T spots an attractive man getting out of an Uber. A conversation ensues and later on that nite they become better acquainted! </p>
            <hr />

            <p><strong>Sunday Siesta</strong><br/>
            After a long work week, T and her lover decide to relax all weekend and enjoy each other's company.</p>
            <hr />

            <p><strong>O Therapy </strong><br/>
            Feeling a bit stressed out and tense, T's lover uses an alternative form of therapy to help ease her pressures. </p>
            <hr />

            <p><strong>Just Desserts</strong><br/>
            On the way to Dubai for a work engagement with her lover, T gets distracted and than seduced by an attractive flight attendant. </p>
            <hr />

            <p><strong>Lip Service</strong><br/>
            T's lips with her signature beauty mark are quite popular with her fans, and she isn't shy to use them to her advantage wink!</p>

          </div>

        </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default CakeEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/girlfriday-ep.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
     } 
  }
}
}
`